import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Spinner as PhosphorSpinner } from '@phosphor-icons/react';

const rotatingOffset = keyframes`
  from{
    --offset: 22.5deg;
  }
  12.5% {
    --offset: 67.5deg;
  }
  25% {
    --offset: 112.5deg;
  }
  37.5% {
    --offset: 157.5deg;
  }
  50% {
    --offset: 202.5deg;
  }
  62.5% {
    --offset: 247.5deg;
  }
  75% {
    --offset: 292.5deg;
  }
  87.5% {
    --offset: 337.5deg;
  }
  to{
    --offset: 22.5deg;
  }
`;

export const Spinner = styled(PhosphorSpinner)`
  animation: ${rotatingOffset} 0.8s step-end infinite;
  --offset: 22.5deg;
  --mask: conic-gradient(from var(--offset), transparent 45deg, black);
  mask: var(--mask);
`;
