import styled from '@emotion/styled';

import { Spinner } from './spinner';

const FixedContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FixedSpinner = () => {
  return (
    <FixedContainer>
      <Spinner size="36" />
    </FixedContainer>
  );
};
